import * as React from 'react';
import { IMyAppsProps } from './IMyAppsProps';
import './MyApps.css';
import { Button, Col, Modal, Row } from 'antd';
import { useAppContext } from '../../Hooks/useAppContext';
import VacationContainer from '../Vacation/VacationContainer/VacationContainer';
import { useVacation } from '../../Hooks/useVacation';
import { useHeader } from '../../Hooks/useHeader';


const MyApps: React.SFC<IMyAppsProps> = (props) => {

    const { showModalVacation, handleCloseModalVacation, isModalVisibleVacation,componentType } = useVacation();
    const { openURL } = useHeader();

    return (
        <div>
            <Row >
                <Col xs={24} sm={24} md={24} lg={24} xl={24}
                    className='marginBottom'
                    onClick={() => {openURL('https://teams.microsoft.com/_#/?lm=deeplink&lmsrc=officeWaffle&login_hint_safe=');props.setDrawerVisible(false)}}
                >
                    <img src={require('../../Assets/teamsIcon.png')} /> <span className='enlaces'>Microsoft Teams</span><br />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}
                    className='marginBottom'
                    onClick={() => openURL('https://outlook.office.com/mail/')}>
                    <img src={require('../../Assets/outlookIcon.png')} /> <span className='enlaces'>Outlook</span>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}
                    className='marginBottom'
                    onClick={() => openURL('https://www.office.com/?auth=2')}>
                    <img src={require('../../Assets/o365Icon.png')} />  <span className='enlaces'>Office 365</span>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}
                    className='marginBottom'
                    onClick={() => openURL('https://powerbi.microsoft.com/es-es/')}>
                    <img src={require('../../Assets/graficIcon.png')} />  <span className='enlaces'>Power BI</span>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}
                    className='marginBottom'
					onClick={() => openURL('https://management.qualitasfunds.com/MyEmployeeArea/MyVacations')}>
                    <img src={require('../../Assets/vacationIcon.png')} /> <span className='enlaces'>Mis vacaciones</span>
                </Col>
               {/*  <Col xs={24} sm={24} md={24} lg={24} xl={24}
                    className='marginBottom'
                    onClick={()=>{showModalVacation('Teletrabajo');props.setDrawerVisible(false)}}>
                    <img src={require('../../Assets/remoteWork.png')} /> <span className='enlaces'>Teletrabajo</span>
                </Col> */}
            </Row>

            <Modal
                width={900}
                visible={isModalVisibleVacation}
                onCancel={handleCloseModalVacation}
                footer={[
                    <Button key="submit" type="primary" onClick={handleCloseModalVacation}>
                        Ok
                    </Button>,
                ]}
            >
                <VacationContainer type={componentType} />
            </Modal>
        </div>
    );
};

export default MyApps;
